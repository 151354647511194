<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">PREP</h2>

          <b-button v-b-toggle.collapse-1 variant="primary" class="w-100 d-block">
            Conoce el PREP
          </b-button>
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/que-es"
              >
                ¿Qué es el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/como-opera"
              >
                ¿Cómo opera el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/difusores-oficiales"
              >
                Difusores oficiales del PREP 2024
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/linea-tiempo"
              >
                Línea del Tiempo PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-2 variant="primary" class="w-100 d-block">
            Navegación PREP
          </b-button>
          <b-collapse visible id="collapse-2" class="mt-2">
            <b-list-group>
              <b-list-group-item
                active
                to="/centro-de-ayuda/prep/navegacion/como-navego"
              >
                ¿Cómo navego en el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/elemento-apoyo"
              >
                Elementos de apoyo
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-3 variant="primary" class="w-100 d-block">
            Actas en PREP
          </b-button>
          <b-collapse visible id="collapse-3" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/que-es-acta"
              >
                ¿Qué es el Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/consultar-imagen"
              >
                ¿Cómo consultar la imagen del Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado"
              >
                Estatus, Digitalización y Traslado del Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/observaciones"
              >
                Observaciones en Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/validez"
              >
                Validez del Acta PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button
            to="/centro-de-ayuda/prep/base-de-datos/interpretacion"
            variant="primary"
            class="w-100 d-block"
          >
            Base de Datos
          </b-button>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Navegación en PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                ¿Cómo navego en el PREP?
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            ¿Cómo navego en el PREP?
          </h2>

          <b-embed
            type="video"
            aspect="16by9"
            controls
          >
            <source
              src="https://ayuda.ine.mx/2024/informate/assets/video/Como_navego_PREP.mp4"
              type="video/mp4"
            >
          </b-embed>
          <br />

          <p class="mb-3">
            Conoce los elementos generales para consultar la información presentada en
            el sitio PREP 2024 Elecciones Federales.
            A continuación te mostraremos cada uno de ellos.
          </p>

          <h3 class="color-institucional">
            Logotipos
          </h3>

          <img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/navego-1.svg" alt="" class="img-fluid mx-auto d-block mb-4">

          <div class="row">
            <div class="col-12 col-lg-6 mb-3">
              <h4>
                1.
                <small>
                  El logotipo del INE, dirige al portal del Instituto.
                </small>
              </h4>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <h4>
                2.
                <small>
                  El logotipo del PREP 2024, dirige siempre a la página de inicio del sitio web.
                </small>
              </h4>
            </div>
          </div>

          <div class="row seccion-alterno py-5">
            <div class="col-12 mb-3">
              <h4 class="mb-3">
                Menú principal
              </h4>

              <img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/navego-2.svg" alt="" class="img-fluid mx-auto d-block mb-4">
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <p>
                <b>
                  <span class="mr-3">1</span>
                  Botón Inicio:
                </b>
                regresa a la pantalla principal del sitio.
              </p>

              <p>
                <b>
                  <span class="mr-3">2</span>
                  Tipo de elección:
                </b>
                muestra el tipo de elección consultada: Diputaciones.
              </p>

              <p>
                <b>
                  <span class="mr-3">3</span>
                  Preguntas frecuentes:
                </b>
                redirecciona a la página sobre los conceptos clave y navegación del sitio.
              </p>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <p>
                <b>
                  <span class="mr-3">4</span>
                  Centro de ayuda:
                </b>
                sitio independiente en el que se explican aspectos
                relacionados con el proceso electoral.
              </p>

              <p>
                <b>
                  <span class="mr-3">5</span>
                  Tamaño de fuente y Tema del sitio:
                </b>
                brinda opciones de accesibilidad para una mejor experiencia del usuario.
              </p>

              <p>
                <b>
                  <span class="mr-3">6</span>
                  Base de datos,
                </b>
                dirige a la guía de consulta y descarga de la información de ésta.
              </p>
            </div>
          </div>

          <div class="row py-5 align-items-center">
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                Avance
              </h4>
              <p class="mb-4">
                Muestra los resultados
              </p>

              <p>
                <b>
                  <span class="mr-3">1</span>
                  Actas Capturadas,
                </b>
                número y porcentaje.
              </p>

              <p>
                <b>
                  <span class="mr-3">2</span>
                  Participación ciudadana
                </b>
                en porcentaje hasta el momento.
              </p>

              <p>
                <b>
                  <span class="mr-3">3</span>
                  Último corte,
                </b>
                fecha y hora de la última actualización de los datos
                e imgenes del <b>PREP</b>
              </p>

              <p>
                <b>
                  <span class="mr-3">4</span>
                  Botón Actualizar,
                </b>
                permite cargar la información más reciente.
              </p>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/navego-3.svg" alt="" class="img-fluid mx-auto d-block mb-4">
            </div>
          </div>

          <div class="row py-5 seccion-alterno align-items-center">
            <div class="col-12 col-lg-6 mb-3">
              <h4>
                Conoce los resultados de tu sección
              </h4>
              <p class="mb-4">
                Consulta los resultados de la casilla en la que votaste, ingresa los datos.
              </p>

              <p>
                <b>
                  <span class="mr-3">1</span>
                  Entidad
                </b>
              </p>
              <p>
                <b>
                  <span class="mr-3">2</span>
                  Sección
                </b>
              </p>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/navego-4.svg" alt="" class="img-fluid mx-auto d-block mb-4">
            </div>
          </div>

          <div class="row py-5 align-items-center">
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                Tipo de reporte
              </h4>
              <p class="mb-4">
                Conoce la información que podrás consultar de
                acuerdo con el tipo de reporte seleccionado
              </p>

              <p>
                <b>
                  <span class="mr-3">1</span>
                </b>
                Consulta de votación
              </p>

              <ul>
                <li>
                  Votos por Distrito o Municipio
                </li>
                <li>
                  Votos por Partido Político y Candidatura Independiente
                </li>
              </ul>

              <p>
                <b>
                  <span class="mr-3">2</span>
                </b>
                En nivel Detalle por Distrito o Municipio
              </p>

              <ul>
                <li>
                  Votos por Candidatura
                </li>
                <li>
                  Votos por Partido Político y Candidatura Independiente
                </li>
                <li>
                  Detalle por Casilla
                </li>
              </ul>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/navego-7.svg" alt="" class="img-fluid mx-auto d-block mb-4">
            </div>
          </div>

          <div class="row py-5 seccion-alterno align-items-center">
            <div class="col-12 col-lg-6 mb-3">
              <h4>
                Vistas del reporte
              </h4>
              <p class="mb-4">
                Conoce la información por tres tipos de vista
                de acuerdo con el tipo de reporte seleccionado, los cuales son:
              </p>

              <p>
                <b>
                  <span class="mr-3">1</span>
                  Vista Mapa
                </b>
              </p>
              <p>
                <b>
                  <span class="mr-3">2</span>
                  Vista Tarjetas
                </b>
              </p>
              <p>
                <b>
                  <span class="mr-3">3</span>
                  Vista Gráfica
                </b>
              </p>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/navego-8.svg" alt="" class="img-fluid mx-auto d-block mb-4">
            </div>
          </div>

          <div class="row py-5 align-items-center">
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                Nivel Detalle del reporte
              </h4>
              <p class="mb-4">
                Conoce la información por diferentes niveles,
                de acuerdo con el tipo de reporte seleccionado.
              </p>

              <p>
                <b>
                  <span class="mr-3">1</span>
                  Detalle por:
                </b>
              </p>

              <ul>
                <li>Distrito</li>
                <li>Municipio</li>
                <li>Sección</li>
                <li>Casilla</li>
              </ul>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/navego-9.svg" alt="" class="img-fluid mx-auto d-block mb-4">
            </div>
          </div>

          <div class="row py-5 seccion-alterno align-items-center">
            <div class="col-12 mb-3">
              <h4 class="mb-3">
                Conoce tu rastro de navegación
              </h4>

              <img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/navego-10.svg" alt="" class="img-fluid mx-auto d-block mb-3">

              <p class="mb-3">
                1. Indica tu rastro de navegación de acuerdo con la elección consultada.
              </p>
              <p class="mb-3">
                Al dar clic en la ruta de navegación marcada de color
                azul podrás moverte entre los contenidos del sitio.
              </p>
            </div>
          </div>

          <div class="row py-5 align-items-center">
            <div class="col-12 col-lg-6 mb-3">
              <h4 class="color-institucional">
                Botón Compartir
              </h4>
              <p class="mb-4">
                Permite compartir en redes sociales (Twitter, Facebook y WhatsApp) el sitio PREP.
                Al dar clic sobre el ícono de cualquier red social,
                se despliega la ventana para compartir el sitio.
              </p>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/navego-11.svg" alt="" class="img-fluid mx-auto d-block mb-4">
            </div>
          </div>

          <div class="row py-5 seccion-alterno align-items-center mb-5">
            <div class="col-12 mb-3">
              <h4>
                Estructura de tipos de reporte
              </h4>
              <p class="mb-3">
                Conoce la información contenida en los diferentes
                tipos de reporte de acuerdo con el nivel.
              </p>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <ul _ngcontent-qrr-c66=""><li _ngcontent-qrr-c66=""> Votos en Actas contabilizadas </li><li _ngcontent-qrr-c66=""> Resumen de la votación </li><li _ngcontent-qrr-c66=""> Representación Proporcional </li><li _ngcontent-qrr-c66=""> Estadística </li><li _ngcontent-qrr-c66=""> Observaciones en Acta </li></ul>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/navego-12.svg" alt="" class="img-fluid mx-auto d-block mb-4">
            </div>
          </div>

          <p>
            <b>
              Las imágenes utilizadas en esta infografía son representativas. 
            </b>
          </p>
          <br />
          <br />

          <a
            href="https://ayuda.ine.mx/2024/informate/assets/docs/Prep/Navegacion_en_PREP_Infografias/Como_Navego_en_el_PREP.pdf"
            target="_blank"
            class="btn btn-primary mx-auto d-block"
          >
            Descarga Infografía
          </a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  margin-left: 30px;
}

.list-group-item {
  z-index: 0;
}

.grid-tiempo {
  display: grid;
  align-items: center;
  grid-template-columns: 25% 1fr;
  color: white;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }

  .grid-interno {
    display: grid;
    align-items: center;
    gap: 45px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &:nth-child(odd) {
    background-color: #766CAE;

    .detalle-block {
      background-color: #5C5688;
    }

    .descripcion-block {
      background-color: #4C4871;
    }
  }

  &:nth-child(even) {
    background-color: #E50077;

    .detalle-block {
      background-color: #962464;
    }

    .descripcion-block {
      background-color: #6B1046;
    }
  }
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}
</style>
